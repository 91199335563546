.css-1cohrqd-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 45px !important;
    width: 100%;
}


/*table row pading*/
.css-1nos2st {
    padding-bottom: 5px !important;
    padding-top: 5px !important;

}

.css-eev6vi.css-eev6vi.css-eev6vi{
    padding: 0.75rem !important;
}

.css-eev6vi.MuiSelect-select{
    padding: 0.75rem !important;
}